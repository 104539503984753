@import url('https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #272727;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  color: #fff;
	background-color: #000000;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: helvetica neue, helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

header {
  color: #fff;
  padding: 20px;
  text-align: center;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
